<template>
  <div id="app1">
    <div class="header" @click="goBack">
      <van-icon size="20" name="arrow-left" />
      <span>企业信息</span>
    </div>
    <div class="box1">
      <div class="box1Title PB_4">
        <div class="W_15 MR_2">
          <img class="w_100"  src="../assets/nologo@2x.png" alt="">
        </div>
        <div class="W_85">
          <div class="fontS_18 MB_1 font_weight_bold text_overflow_1">{{companyInfo.company}}</div>
          <div class="btn2" v-if="companyInfo.REGORG != '' && companyInfo.REGORG != null">
            <van-icon  size="15" color="#99A0AD" name="location-o" />
            登记机关{{companyInfo.REGORG}}
          </div>
          <div style="display:flex;" v-if="enterprise_tags.length > 0">
             <div class="btn3 MR_2" v-for="(item,index) in enterprise_tags" :key="index">{{item}}</div>
          </div>
          <div class="W_50" style="color:#888888;display:flex;">企业人数 {{companyInfo.number ? companyInfo.number : '暂无'}}</div>
          <div class="MT_2 text_overflow_1" style="color:#888888;display:flex;">地址：{{companyInfo.address ? companyInfo.address : '暂无'}}</div>
          
        </div>
      </div>
      <div class="companyL MT_3">
        <div >
          <div class="companyLTitle">法定代表人</div>
          <div class="companyContent MT_1">{{companyInfo.FRNAME ? companyInfo.FRNAME : '暂无'}}</div>
        </div>
          <div>
          <div class="companyLTitle">注册资本(万元)</div>
          <div class="companyContent MT_1">{{companyInfo.reg_cap ? Number(companyInfo.reg_cap).toFixed(0) : '暂无'}}</div>
        </div>
          <div>
          <div class="companyLTitle">成立日期</div>
          <div class="companyContent MT_1">{{companyInfo.add_time ? companyInfo.add_time : "暂无"}}</div>
        </div>
      </div>
    </div>

    <div class="box2 MT_3">
      <div class="box2Title">经营范围</div>
      <div class="cox2Content MT_2 " v-if="companyInfo.ZSOPSCOPE">{{companyInfo.ZSOPSCOPE}}</div>
      <div class="cox2Content MT_2 " v-else >暂无数据</div>
    </div>

    <div class="box2 MT_3">
      <div class="box2Title">企业介绍</div>
      <div class="cox2Content MT_2" v-if="companyInfo.enterprise_introduce != null">{{companyInfo.enterprise_introduce}}</div>
      <div class="cox2Content MT_2" v-else>暂无数据</div>
    </div>

    <div class="box2 MT_3">
      <div class="box2Title">企业相册</div>
      <img class="MT_2" v-if="enterprise_imgs.length == 0" src="../assets/companyphoto@2x.png" alt="">
      <div  style="width:100%;height:10rem;display:flex; overflow-x: auto;" v-else>
        <img @click="priview(index)" style="margin-right:1rem" fit="cover" v-for="(item,index) in enterprise_imgs"  :key="index"   :src="item" alt="">
      </div>
    </div>

    <div class="box2 MT_3">
      <div class="box2Title MB_2">企业视频</div>
      <img class="MT_2" v-if="video.url == ''" src="../assets/companyvido@2x.png" alt=""> 
      <vueMiniPlayer v-else ref="vueMiniPlayer" :video="video" :mutex="true"  />
    </div>

    <div class="box2 MT_3">
      <div class="box2Title">企业主要人员</div>
      <div class="MT_3" v-if="companyInfo.PERSON.length > 0" style="display:flex">
        <div class="MR_5" v-for="(item,index)  in companyInfo.PERSON" :key="index">
          <div class="companyLTitle">{{item.PERNAME}}</div>
          <div class="companyContent MT_1">{{item.POSITION}}</div>
        </div>
      </div>
      <div v-else class="cox2Content MT_2">暂无数据</div>
    </div>

    <div class="box2 MT_3">
      <div class="box2Title">最新新闻</div>
      <div class="cox2Content MT_2" v-if="companyInfo.news == null || companyInfo.news == ''">暂无数据</div>
      <div class="cox2Content MT_2" v-else>{{companyInfo.news}}</div>
      
    </div>
  </div>
  
</template>
<script>
import { mapActions } from 'vuex'
import { ImagePreview } from 'vant';
export default {
  data(){
    return {
      companyInfo:{},
      score:{},
      enterprise_tags:[],
      enterprise_vlog:[],
      enterprise_imgs:[],
     
      video: {
          // 视频播放源
          url: '',
          // 视频海报
          cover: '',
          muted: false, // 是否静音播放
          loop: false, // 视频是否循环播放
          preload: 'auto', // 视频预加载
          poster: '', // 原生视频默认海报暂不设置
          volume: 1, // 默认音量
          autoplay: false, // 视频自动播放
        }
 
    } 
  },
  mounted(){
    this.getDetail()
  },
  methods:{
    ...mapActions(["getScore"]),
    goBack(){
      this.$router.go(-1)
    },
    getDetail(){
      this.getScore({
        reqType: "login", //请求类型
        company: this.$route.query.company, //公司
        uniformCreditCode: this.$route.query.uniformCreditCode //税号
      }).then((res)=>{
        console.log(res)
        this.companyInfo = res.data.companyInfo
        this.score = res.data.score
        if(res.data.companyInfo.enterprise_tags) {
          this.enterprise_tags = JSON.parse(res.data.companyInfo.enterprise_tags) 
        }
        console.log(res.data.companyInfo.enterprise_imgs)
        this.enterprise_imgs = res.data.companyInfo.enterprise_imgs ? res.data.companyInfo.enterprise_imgs = res.data.companyInfo.enterprise_imgs.split(",") : [] 
        if(res.data.companyInfo.enterprise_vlog != null){
          this.video.url = res.data.companyInfo.enterprise_vlog
        }
        if(res.data.companyInfo.video_cover != '') {
          this.video.cover = res.data.companyInfo.video_cover
        }
        
        
      })
    },
    priview(index){
      ImagePreview({
        images: this.enterprise_imgs,
        startPosition: index,
      });
    }
  }
}
</script>

<style scoped>
.pause{
  position: absolute;
  left:43%;
  top:30%;
}
.imgsty{
  margin-top:1rem;
}
 #app1{
   background-color: #F7F7F7;
  /* height:100vh; */
}
.companyL{
  display: flex;
  justify-content: space-around;

}
.header{
  padding:1rem;
}
.box1,.box2{
  width:100%;
  padding:1rem;
  background:#fff;
}
 

.box1Title{
  display: flex;
  border-bottom:0.1rem solid #F4F4F4;
}
.btns{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.btn1{
  /* text-align: center; */
  /* padding:0.1rem; */
  border:0.1rem solid #49C880;
  color:#49C880;
  border-radius: 0.2rem;
  padding:0.2rem;
  width:38%;
  text-align: center;
}
.btn2{
  text-align: center;
  width: 80%;
  padding:0.1rem;
  border:0.1rem solid #99A0AD;
  color:#99A0AD;
  border-radius: 0.2rem;
  margin:0.4rem 0;
}
.btn3{
  text-align: center;
   /* width: 20%; */
  padding:0.1rem 0.2rem ;
  border:0.1rem solid #221657;
  color:#221657;
  border-radius: 0.2rem;
}
.companyLTitle{
  color: #888888;
}
.companyContent{
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #161957;
}
.box2Title{
  font-size: 1.5rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #222222;
}
.cox2Content{
  font-size: 1.2rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #8292B0;
}
 
</style>